import {TranslocoService} from '@ngneat/transloco'
import {map} from 'rxjs/operators'
import {Observable, of, zip} from 'rxjs'
import {tryTranslatingKey} from '../i18n/i18n.utils'
import {Thing, WithContext} from 'schema-dts'
import {Language} from '../i18n/language'

export type OpenGraphType = 'website' | 'article' | 'profile'
export type TwitterCard = 'summary' | 'summary_with_large_image'

/**
 * Structured Data
 *
 * We currently "limit" us to the structured data defined by schema.org
 *
 * But also enforce a @context field, which is technically not required.
 *
 * @see https://schema.org/docs/full.html
 */
export type StructuredData = WithContext<Thing>

/**
 * Components that use the <bgo-json-ld> component should implement this interface, for type safety
 *
 * Components that extend PageComponent might also benefit from implementing it for more explicit type safety.
 */
export interface HasStructuredData<T extends Thing = Thing> {
  getStructuredData$(): Observable<WithContext<T> | undefined>
}

export interface PageMetadata {
  title?: string
  description?: string
  imageUrl?: string
  type?: OpenGraphType
  twitterCard?: TwitterCard
  doNotIndexPage?: boolean
  structuredData?: StructuredData
  canonicalUrl?: string
}

export interface ComponentWithMetadata {
  getPageMetadata(): Observable<PageMetadata>
  getPageAvailableLanguages(): Observable<Language[] | undefined>
}

export abstract class PageComponent implements ComponentWithMetadata {
  abstract getPageMetadata(): Observable<PageMetadata>

  /**
   * This is used to generate links tags that refer to versions of the page in other languages in the <head>
   */
  getPageAvailableLanguages(): Observable<Language[] | undefined> {
    return of(undefined)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function hasPageMetadata(obj: any): obj is ComponentWithMetadata {
  return typeof obj !== 'undefined' && typeof obj?.getPageMetadata === 'function'
}

export function translatePageMetadata(i18n: TranslocoService, meta: PageMetadata): Observable<PageMetadata> {
  const {title, description} = meta

  const resolveMetadataKey = (value: string | undefined) => {
    if (value) {
      return tryTranslatingKey(i18n, value)
    }

    return of(undefined)
  }

  return zip(resolveMetadataKey(title), resolveMetadataKey(description)).pipe(
    map(([title, description]) => ({title, description})),
    map(translatedFields => ({...meta, ...translatedFields})),
  )
}

export function getTranslatablePageMetadataKeys(prefix: string): PageMetadata {
  return {
    title: `${prefix}.metadata.title`,
    description: `${prefix}.metadata.description`,
  }
}
